<template>
  <div class="discover-statistics">
    <div class="d-c-title">发现企业</div>
    <el-row>
      <el-col :span="18">
        <div class="d-s-content-title">根据您的招商需求描述, 大数据实时为您推荐以下项目</div>
        <div class="d-s-content-title">点击跟进该企业, 可在招商OA中实时掌握公司最新信息动态</div>
      </el-col>
      <el-col :span="5">
        <el-button @click="$router.push('/enterpriseTracking')" type="primary" size="medium">招商OA</el-button>
        <el-button @click="editBusinessSingle" type="primary" size="medium">修改招商条件</el-button>
      </el-col>
    </el-row>
    <el-table :data="discoverStatisticsData" style="width: 100%;margin: 20px 0 10px 0;">
      <el-table-column width="50" align="center">
        <template slot-scope="scope">
          <el-checkbox :disabled="scope.row.is_follow" v-model="scope.row.flag" @change="checkboxChange(scope.row)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="address" align="center" label="所在地" width="150"></el-table-column>
      <el-table-column prop="brand_name" align="center" label="品牌名称"></el-table-column>
      <el-table-column prop="company_name" align="center" label="企业名称"></el-table-column>
      <el-table-column prop="create_time" align="center" label="注册时间" width="120"></el-table-column>
      <el-table-column align="center" label="行业领域" width="200">
        <template slot-scope="scope">
          <el-tag type="info" v-for="(item, index) in scope.row.industry" :key="index">{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="stag_name" align="center" label="融资轮次" width="100"></el-table-column>
      <el-table-column prop="user_name" align="center" label="负责人" width="90"></el-table-column>
      <el-table-column prop="weight" align="center" label="当前匹配度" width="100"></el-table-column>
      <el-table-column fixed="right" align="center" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="viewHandleClick(scope.row)" size="mini">查看权重比</el-button>
          <el-button type="primary" v-if="!scope.row.is_follow" @click="followView(scope.row)" size="mini">跟进</el-button>
          <el-button type="primary" v-else disabled size="mini">已跟进</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="space-between">
      <el-col :span="2" class="all-select">
        <!--<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>-->
        <el-checkbox :indeterminate="isIndeterminate" :disabled="alldisabled" v-model="selectAllFlag" @change="changeAllSelect">全选本页</el-checkbox>
      </el-col>
      <el-col class="all-select" :span="2"><el-button @click="batchSubmit" type="primary" size="mini">批量跟进</el-button></el-col>
    </el-row>
    <Page @currentPageChange="discoverStatisticsPage"
          :pageSize="discoverStatisticsMeta.per_page"
          :page="discoverStatisticsMeta.current_page"
          :total="discoverStatisticsMeta.total" style="text-align: center;margin: 20px 0 80px 0"></Page>

    <!--权重比弹窗详情-->
    <el-dialog
            :visible.sync="discoverStatisticsDialog"
            width="60%">
      <el-table :data="viewDetail" style="width: 100%;margin: 20px 0 10px 0;">
        <el-table-column prop="one_grade.name" align="center" label="一级条件" width="150"></el-table-column>
        <el-table-column prop="two_grade.name" align="center" label="二级条件"></el-table-column>
        <el-table-column prop="custom_weight" align="center" label="设定权重"></el-table-column>
        <el-table-column prop="match_weight" align="center" label="匹配度" width="120"></el-table-column>
        <el-table-column prop="stag_name" align="center" label="指标描述" width="100">
          <template slot-scope="scope">
            {{scope.row.hit ? '命中': '未命中'}}
          </template>
        </el-table-column>
      </el-table>
      <div class="table-total">
        <div>当前匹配度: {{viewWeight}}%</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { success, warning, error } from "@/utils/notification";
  import Page from '@/components/PageComponents.vue'
  export default {
    name: "discoverStatistics",
    data() {
      return {
        viewDetail: [],
        viewWeight: '',
        discoverStatisticsDialog: false,
        alldisabled: false,
        temporaryArr: [],
        isIndeterminate: false,
        selectAllFlag: false,
        page: 1,
        organ_id: null,
        demand_id: null,
        discoverStatisticsMeta: {},
        discoverStatisticsData: []
      }
    },
    watch: {
      temporaryArr: { // 监听取值数组，对全选按钮的状态判断
        handler(a) {
          if (a.length === 0) {
            this.isIndeterminate = false
            this.selectAllFlag = false
          }
          let number = 0
          this.discoverStatisticsData.forEach(item => {
            if (!item.is_follow) {
              number++
            }
          })
          if (a.length < number && a.length > 0) {
            this.isIndeterminate = true
            this.selectAllFlag = false
          }
          if (a.length === number) {
            this.isIndeterminate = false
            this.selectAllFlag = true
          }
        },
        deep: true
      },
      discoverStatisticsData: {
        handler(a) {
          let number = 0
          let allSelectNumber = 0
          a.forEach(item => {
            if (item.is_follow) {
              number++
            }
            if (item.flag) {
              allSelectNumber++
            }
          })
          if (a.length === 0) {
            this.alldisabled = false
            this.selectAllFlag = false
          } else {
            this.alldisabled = number === a.length ? true : false
            this.selectAllFlag = allSelectNumber === a.length ? true : false
          }

        },
        deep: true
      }
    },
    methods: {
      editBusinessSingle() {
        this.$router.push({
          path: '/createBusinessCondition',
          query: {id: this.$route.query.demand_id}
        })
      },
      viewHandleClick(row) {
        this.$http.get(this.URL.weight, {
          params: {
            organ_id: this.organ_id,
            match_id: row.id
          }
        }).then(r =>{
          console.log(r.data.data.data, '空开快红开坑')
          this.viewDetail = r.data.data.data
          this.viewWeight = r.data.data.weight
          this.discoverStatisticsDialog = true
        })
      },
      batchSubmit() { // 批量处理
        if (this.temporaryArr.length === 0) {
          warning('请勾选需要批量跟进的数据...')
        } else {
          this.followUpFun(this.temporaryArr)
        }
      },
      changeAllSelect(flag) {
        this.discoverStatisticsData.forEach((item, index) => {
          this.$set(item, 'flag', flag && !item.is_follow ? true : false)
          if (flag) {
            !item.is_follow && this.temporaryArr.push(item.id)
            this.temporaryArr = Array.from(new Set(this.temporaryArr))
          } else {
            this.temporaryArr.splice(this.temporaryArr.indexOf(item.id), 1)
          }
        })
      },
      followUpFun(enterprise_ids) {
        this.$http.post(this.URL.follow, {
          organ_id: this.organ_id,
          enterprise_ids
        }).then(r => {
          success('匹配企业跟进成功....')
          this.discoverStatisticsData.forEach(item =>{
            if (enterprise_ids.indexOf(item.id) > -1) {
              item.is_follow = true
              this.$set(item, 'flag', false)
            }
          })
          this.isIndeterminate = false
          this.selectAllFlag = false
          this.temporaryArr = []
        }).catch(_ => {
          error(_.response.data.message)
        })
      },
      followView(row) { // 跟进
        this.followUpFun([row.id])
      },
      checkboxChange(item) {
        console.log(item, 'ksdf')
        if (item.flag) {
          this.temporaryArr.push(item.id)
        } else {
          this.temporaryArr.splice(this.temporaryArr.indexOf(item.id), 1)
        }


      },
      discoverStatisticsPage(n) {
        this.page = n
        this.bassicFun()
      },
      bassicFun() {
        this.$http.get(this.URL.matelists, {
          params: {
            demand_id: this.demand_id,
            organ_id: this.organ_id,
            page: this.page
          }
        }).then(r => {
          this.discoverStatisticsData = r.data.data.data
          this.discoverStatisticsMeta = r.data.data.meta
          this.discoverStatisticsData.forEach(item => {
            item.create_time = item.create_time.split(' ')[0]
            this.$set(item, 'flag', false)
          })
        }).catch(_ => {
          error(_.response.data.message)
        })
      }
    },
    created() {
      this.demand_id = Number(this.$route.query.demand_id)
      this.organ_id = Number(this.$route.query.organ_id)
      setTimeout(() => {
        this.bassicFun()
      }, 1000)
    },
    components: {
      Page
    }
  }
</script>

<style scoped lang="scss">
  .d-c-title{
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  .d-s-content-title{
    color: #409EFF;font-size: 14px;line-height: 20px;
  }
  .all-select{
    text-align: center;
    &:nth-child(1) {
      padding-left: 9px;
    }
  }
  .table-total{
    display: flex;justify-content: flex-end;
    margin-top: 14px;
    padding-right: 30px;
    color: #333
  }
</style>
<style lang="scss">
  .discover-statistics{
    .el-tag{
      padding: 0 4px;
      height: 26px;
      line-height: 26px;
    }
  }

</style>
